




















































































import { Component, Vue, Model, Emit, Prop, Watch } from 'vue-property-decorator'
import {
  CurriculumController,
  DropDownController,
  CourseController,
} from '@/services/request.service'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { i18n } from '@/i18n/i18n'
import { createPagination } from '@/constant/constant'

@Component
export default class AddSubstituteTeacher extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly adjustData!: any

  private filter: any = {
    time: [],
    subjectIds: [],
    name: '',
  }
  private subjects: Array<any> = []
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private totalData: Array<any> = []
  private data: Array<any> = []
  private selectedTeachers: Array<any> = []
  private selectedRowKeys: Array<any> = []

  private moment = moment

  private get columns(): Array<any> {
    return [
      {
        key: 'teacher',
        title: this.$t('adjustCurriculum.teacher'),
        scopedSlots: { customRender: 'name' },
        align: 'left',
      },
      {
        key: 'subject',
        title: this.$t('adjustCurriculum.subject'),
        align: 'left',
        scopedSlots: { customRender: 'subject' },
      },
    ]
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  @Watch('visible')
  private onVisibleChange(): void {
    this.getDropDownInfo()
    this.selectedRowKeys = this.adjustData.teachers.map(teacher => teacher.teacherId)
    this.selectedTeachers = this.adjustData.teachers
    this.$set(this.filter, 'time', [this.adjustData.start, this.adjustData.end])
  }

  private getDropDownInfo(): void {
    DropDownController.getSubjectList()
      .then(res => {
        this.subjects = res.data
        this.filter.subjectIds = [this.adjustData.subjectId]
        this.findTeacher()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private findTeacher(page: any = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    const { time, subjectIds, name } = this.filter
    const { current, pageSize } = page
    if (!current) {
      CurriculumController.getAvailableTeachers({
        start: time[0] || undefined,
        end: time[1] || undefined,
        subjectIds: subjectIds || undefined,
        name: name || undefined,
      })
        .then(res => {
          this.totalData = res.data
          const size = pageSize || this.pagination.defaultPageSize
          this.data = this.totalData.slice(0, size)
          this.$set(this.pagination, 'total', res.data.length)
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      const size = pageSize || this.pagination.defaultPageSize
      this.data = this.totalData.slice((current - 1) * size, current * size)
    }
  }

  private changeName = debounce(() => {
    this.findTeacher()
  }, 500)

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    const page = {
      current: pagination.current,
      pageSize: pagination.pageSize,
    }
    this.findTeacher(page)
  }

  private onSelectChange(selectedRowKeys): void {
    this.selectedRowKeys = selectedRowKeys
    this.selectedTeachers = this.data
      .filter(item => selectedRowKeys.includes(item.teacherId))
      .map(item => {
        return {
          teacherId: item.teacherId,
          name: item.name,
        }
      })
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit()
  private confirm(): any {
    this.closeModal()
    return this.selectedTeachers
  }
}

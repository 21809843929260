








































































































import { Component, Vue } from 'vue-property-decorator'
import { TeacherController, CurriculumController } from '@/services/request.service'
import AdjustEditor from './components/AdjustEditor.vue'
import AdjustViewer from './components/AdjustViewer.vue'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import { toPage } from '@/utils/utils'
import filterGroup from '@/components/filterGroup'
import RejectModal from '@/components/RejectModal.vue'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    AdjustEditor,
    AdjustViewer,
    filterGroup,
    RejectModal,
  },
})
export default class AdjustApproval extends Vue {
  private statuses: Array<any> = ['waitingForAudit', 'rearranged', 'terminated', 'expired']
  private teachers: Array<any> = []
  private filter: any = {
    applyTime: [],
    status: 0,
    applier: 0,
  }
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private loading: boolean = false
  private data: Array<any> = []
  private editorVisible = false
  private modificationId = 0
  private rejectVisible: boolean = false
  private terminateId: any
  private viewerVisible = false
  private viewerInfo: any = {
    teacher: '',
    time: [],
    status: '',
  }

  private moment = moment

  private get columns(): Array<any> {
    return [
      {
        key: 'applyTime',
        title: this.$t('adjustCurriculum.applyTime'),
        align: 'left',
        scopedSlots: { customRender: 'applyTime' },
      },
      {
        dataIndex: 'applier',
        key: 'applier',
        title: this.$t('adjustCurriculum.teacher'),
        align: 'left',
      },
      {
        key: 'status',
        title: this.$t('adjustCurriculum.status'),
        align: 'left',
        scopedSlots: { customRender: 'status' },
      },
      {
        key: 'createTime',
        title: this.$t('adjustCurriculum.createTime'),
        align: 'left',
        scopedSlots: { customRender: 'createTime' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        align: 'left',
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private created(): void {
    this.getDropDownInfo()
    this.getData()
  }

  private getDropDownInfo(): void {
    Promise.all([CurriculumController.getTeacherForArrange()])
      .then(res => {
        this.teachers = res[0].data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private reset(): void {
    this.filter = {
      applyTime: [],
      status: 0,
      applier: 0,
    }
    this.$set(this.pagination, 'current', 1)
    this.getData()
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    const { applyTime, status, applier } = this.filter
    this.data = []
    this.loading = true
    const start = applyTime[0]
      ? moment(applyTime[0])
          .startOf('day')
          .valueOf()
      : undefined
    const end = applyTime[0]
      ? moment(applyTime[1])
          .endOf('day')
          .valueOf()
      : undefined
    CurriculumController.getRearrangeLists({
      pageCurrent: page.current,
      pageSize: page.pageSize,
      start,
      end,
      status: status ? status : undefined,
      applier: applier ? applier : undefined,
    })
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(this.pagination)
  }

  private addAdjust(): void {
    this.editorVisible = true
    this.modificationId = 0
  }

  private adjust(modificationId): void {
    this.editorVisible = true
    this.modificationId = modificationId
  }

  private viewDetail(record): void {
    this.viewerVisible = true
    this.modificationId = record.modificationId
    this.viewerInfo = {
      teacher: record.applier,
      time: [moment(record.start), moment(record.end)],
      status: record.status,
      reason: record.reason,
    }
  }

  private terminate(modificationId): void {
    this.terminateId = modificationId
    this.rejectVisible = true
  }

  private confirmReject(params): void {
    CurriculumController.stopArrange(this.terminateId, params.reason)
      .then(res => {
        this.$message.success(this.$tc('common.terminateSuccess'))
        this.rejectVisible = false
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => this.refresh())
  }

  private refresh(): void {
    this.getData(this.pagination)
  }
}

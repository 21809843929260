


















































































import { Component, Vue, Model, Prop, Emit, Watch } from 'vue-property-decorator'
import {
  CurriculumController,
  DropDownController,
  CourseController,
  TeacherController,
} from '@/services/request.service'
import AddSubstituteTeacher from './AddSubstituteTeacher.vue'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'

@Component({
  components: {
    AddSubstituteTeacher,
  },
})
export default class AdjustEditor extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly modificationId!: any
  private adjustDetail: any = {}

  private filter: any = {
    teacherId: undefined,
    time: [],
  }
  private data: Array<any> = []
  private teachers: Array<any> = []
  private addTeacherVisible = false
  private addTeacherData: any = {}
  private addTeacherId = 0
  private loading = false
  private moment = moment

  private get columns(): Array<any> {
    return [
      {
        key: 'courseTime',
        title: this.$t('adjustCurriculum.courseTime'),
        scopedSlots: { customRender: 'courseTime' },
      },
      {
        dataIndex: 'name',
        key: 'courseClass',
        title: this.$t('adjustCurriculum.courseClass'),
        align: 'left',
      },
      {
        dataIndex: 'subject',
        key: 'subject',
        title: this.$t('adjustCurriculum.subject'),
        align: 'left',
      },
      {
        dataIndex: 'grade',
        key: 'section',
        title: this.$t('adjustCurriculum.section'),
        align: 'left',
      },
      {
        width: '20%',
        key: 'substituteTeacher',
        title: this.$t('adjustCurriculum.substituteTeacher'),
        scopedSlots: { customRender: 'substituteTeacher' },
      },
    ]
  }

  @Watch('visible')
  private onVisibleChange(newVal): void {
    if (newVal) {
      if (this.modificationId) {
        this.getData()
      } else {
        this.getDropDownInfo()
      }
    }
  }

  private range(start, end) {
    let result = [] as any
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  private disabledDate(current): boolean {
    return current && current < moment().startOf('day')
  }

  private disabledTime(dates, type): any {
    if (type === 'start') {
      if (dates && dates[0]) {
        const startTime = moment(dates[0])
        if (
          startTime.startOf('day').valueOf() ===
          moment()
            .startOf('day')
            .valueOf()
        ) {
          const hour = moment().hours()
          const minute = moment().minutes()
          const second = moment().seconds()
          if (dates[0].hours() === hour) {
            if (dates[0].minutes() === minute) {
              return {
                disabledHours: () => this.range(0, hour),
                disabledMinutes: () => this.range(0, minute),
                disabledSeconds: () => this.range(0, second),
              }
            } else {
              return {
                disabledHours: () => this.range(0, hour),
                disabledMinutes: () => this.range(0, minute),
                disabledSeconds: () => [],
              }
            }
          } else {
            return {
              disabledHours: () => this.range(0, hour),
              disabledMinutes: () => [],
              disabledSeconds: () => [],
            }
          }
        }
      }
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    }
  }

  private getDropDownInfo(): void {
    Promise.all([CurriculumController.getTeacherForArrange()])
      .then(res => {
        this.teachers = res[0].data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(): void {
    CurriculumController.getRearrangeById(this.modificationId)
      .then(res => {
        this.adjustDetail = res.data
        this.$set(this.filter, 'time', [moment(res.data.start), moment(res.data.end)])
        this.$set(this.filter, 'teacherId', res.data.applierId)
        this.data = res.data.rearrangeCourses.map(item => {
          return {
            ...item,
            teachers: [],
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getCourseList(): void {
    const time = this.filter.time
    const teacherId = this.filter.teacherId
    if (time.length && teacherId) {
      const start = moment(time[0]).valueOf()
      const end = moment(time[1]).valueOf()
      CurriculumController.getRearrangeAdmin(teacherId, start, end)
        .then(res => {
          this.data = res.data.rearrangeCourses.map(item => {
            return {
              ...item,
              teachers: [],
            }
          })
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  private onSelectChange(params): void {
    if (params) {
      this.$nextTick(() => {
        this.getCourseList()
      })
    }
  }

  private addTeacher(record): void {
    this.addTeacherVisible = true
    this.addTeacherData = record
    this.addTeacherId = record.arrangeId
  }

  private removeTeacher(index, teacherId): void {
    let record = this.data[index]
    const teacherIndex = record.teachers.findIndex(teacher => teacher.teacherId === teacherId)
    record.teachers.splice(teacherIndex, 1)
    this.$set(this.data, index, record)
  }

  private approve(): void {
    const data = this.data
    if (!data.length) {
      this.$message.error(this.$tc('adjustCurriculum.noAdjustCourse'))
      return
    }
    if (data.some(item => !item.teachers.length) || !data.length) {
      this.$message.error(this.$tc('adjustCurriculum.selectTeacher'))
      return
    }
    this.loading = true
    if (this.modificationId) {
      const request = {
        start: moment(this.filter.time[0]).valueOf(),
        end: moment(this.filter.time[1]).valueOf(),
        modificationId: this.modificationId,
        teacherRearranges: this.data.map(item => {
          return {
            arrangeId: item.arrangeId,
            teacherIds: item.teachers.map(it => it.teacherId),
          }
        }),
      }
      if (request.teacherRearranges.length) {
        CurriculumController.rearrangeAudit(request)
          .then(res => {
            this.$message.success(this.$tc('common.adjustSuccess'))
            this.closeModal()
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => (this.loading = false))
      } else {
        this.$message.error(this.$tc('adjustCurriculum.noRecords'))
      }
    } else {
      const teacherRearranges = this.data.map(item => ({
        arrangeId: item.arrangeId,
        teacherIds: item.teachers.map(it => it.teacherId),
      }))
      if (!teacherRearranges.length) {
        return
      }
      const request = {
        start: this.filter.time[0].valueOf(),
        end: this.filter.time[1].valueOf(),
        teacherId: this.filter.teacherId,
        auditRequest: {
          teacherRearranges,
        },
      }
      CurriculumController.rearrangeAdmin(request)
        .then(res => {
          if (res.data.status === 200) {
            this.$message.success(this.$tc('common.adjustSuccess'))
            this.closeModal()
          } else if (res.data.status === 516) {
            const period = res.data.data.crossingPeriods
              .map(item => {
                return (
                  moment(item.start).format('YYYY.MM.DD HH:mm') +
                  ' ~ ' +
                  moment(item.end).format('YYYY.MM.DD HH:mm')
                )
              })
              .join(',')
            this.$error({
              title: this.$t('adjustCurriculum.duplicateTime', { period }),
            })
          } else if (res.data.status === 517) {
            this.$message.error(this.$tc('tips.noCourseArrange'))
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => (this.loading = false))
    }
  }

  private confirmTeacher(teachers): void {
    let data = cloneDeep(this.data)
    data.forEach(item => {
      if (item.arrangeId === this.addTeacherId) {
        item.teachers = teachers
      }
    })
    this.data = cloneDeep(data)
  }

  private cancel(): void {
    this.$confirm({
      title: this.$t('common.unsaveConfirm'),
      onOk: () => {
        this.closeModal()
      },
    })
  }

  @Emit('change')
  private closeModal(): boolean {
    this.filter = {
      teacherId: [],
      time: [],
    }
    this.data = []
    this.teachers = []
    return false
  }
}









































































































































































































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
import { CurriculumController } from '@/services/request.service'
import { getToken, getSchoolInfo } from '@/utils/utils'
import { saveAs } from 'file-saver'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment-timezone'
// import 'moment-timezone'
import flattenDeep from 'lodash/flattenDeep'
import DetailModal from '@/components/DetailModal.vue'

@Component({
  components: {
    DetailModal,
  },
})
export default class MyCurriculum extends Vue {
  @Prop({ default: () => {} }) public readonly curriculumData!: any
  @Prop() public readonly id!: any
  @Prop() public readonly type!: any
  @Prop() public readonly teacherName!: any
  private startTime = moment().startOf('isoWeek')
  private endTime = moment().endOf('isoWeek')
  private exportLoading: boolean = false
  private total = 0
  private substitute = 0
  private courses: any = {}
  private materialInfo: any = {
    title: '',
    infoList: [],
  }
  private detailModalShow: boolean = false

  private moment = moment

  private get week(): Array<any> {
    return [
      {
        name: '周一',
        enName: 'Mon',
        dayOfWeek: 1,
      },
      {
        name: '周二',
        enName: 'Tue',
        dayOfWeek: 2,
      },
      {
        name: '周三',
        enName: 'Wed',
        dayOfWeek: 3,
      },
      {
        name: '周四',
        enName: 'Thu',
        dayOfWeek: 4,
      },
      {
        name: '周五',
        enName: 'Fri',
        dayOfWeek: 5,
      },
      {
        name: '周六',
        enName: 'Sat',
        dayOfWeek: 6,
      },
      {
        name: '周日',
        enName: 'Sun',
        dayOfWeek: 7,
      },
    ]
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get periods(): Array<any> {
    return [
      {
        start: moment()
          .tz('Asia/Shanghai')
          .hours(8)
          .minutes(0),
        end: moment()
          .tz('Asia/Shanghai')
          .hours(9)
          .minutes(0),
      },
      {
        start: moment()
          .tz('Asia/Shanghai')
          .hours(9)
          .minutes(0),
        end: moment()
          .tz('Asia/Shanghai')
          .hours(10)
          .minutes(0),
      },
      {
        start: moment()
          .tz('Asia/Shanghai')
          .hours(10)
          .minutes(0),
        end: moment()
          .tz('Asia/Shanghai')
          .hours(11)
          .minutes(0),
      },
      {
        start: moment()
          .tz('Asia/Shanghai')
          .hours(11)
          .minutes(0),
        end: moment()
          .tz('Asia/Shanghai')
          .hours(12)
          .minutes(0),
      },
      {
        start: moment()
          .tz('Asia/Shanghai')
          .hours(12)
          .minutes(0),
        end: moment()
          .tz('Asia/Shanghai')
          .hours(13)
          .minutes(0),
      },
      {
        start: moment()
          .tz('Asia/Shanghai')
          .hours(13)
          .minutes(0),
        end: moment()
          .tz('Asia/Shanghai')
          .hours(14)
          .minutes(0),
      },
      {
        start: moment()
          .tz('Asia/Shanghai')
          .hours(14)
          .minutes(0),
        end: moment()
          .tz('Asia/Shanghai')
          .hours(15)
          .minutes(0),
      },
      {
        start: moment()
          .tz('Asia/Shanghai')
          .hours(15)
          .minutes(0),
        end: moment()
          .tz('Asia/Shanghai')
          .hours(16)
          .minutes(0),
      },
      {
        start: moment()
          .tz('Asia/Shanghai')
          .hours(16)
          .minutes(0),
        end: moment()
          .tz('Asia/Shanghai')
          .hours(17)
          .minutes(0),
      },
      {
        start: moment()
          .tz('Asia/Shanghai')
          .hours(17)
          .minutes(0),
        end: moment()
          .tz('Asia/Shanghai')
          .hours(18)
          .minutes(0),
      },
    ]
  }

  // private created(): void {
  //   this.getMyCurriculum()
  // }
  @Emit('changeTime')
  private prevWeek(): Array<any> {
    const startTime = moment(this.startTime.subtract(1, 'week')).tz('Asia/Shanghai')
    this.startTime = moment(startTime).tz('Asia/Shanghai')
    const endTime = moment(this.endTime.subtract(1, 'week')).tz('Asia/Shanghai')
    this.endTime = moment(endTime).tz('Asia/Shanghai')
    return [this.startTime, this.endTime]
  }

  @Emit('changeTime')
  private nextWeek(): Array<any> {
    const startTime = moment(this.startTime.add(1, 'week')).tz('Asia/Shanghai')
    this.startTime = moment(startTime).tz('Asia/Shanghai')
    const endTime = moment(this.endTime.add(1, 'week')).tz('Asia/Shanghai')
    this.endTime = moment(endTime).tz('Asia/Shanghai')
    return [this.startTime, this.endTime]
  }

  @Watch('curriculumData', {
    immediate: true,
    deep: true,
  })
  public onValueChange(val): void {
    this.courses = val.courses
    this.total = val.total
    this.substitute = val.substitute
    this.getCrossGroup(this.courses)
  }

  private calcLeft(start): string {
    const startHour = moment(start)
      .tz('Asia/Shanghai')
      .hours()
    const startMinute = moment(start)
      .tz('Asia/Shanghai')
      .minutes()
    return (startHour - 8) * 150 + (startMinute / 60) * 150 + 'px'
  }

  private calcWidth(start, end): string {
    const startHour = moment(start)
      .tz('Asia/Shanghai')
      .hours()
    const startMinute = moment(start)
      .tz('Asia/Shanghai')
      .minutes()
    const endHour = moment(end)
      .tz('Asia/Shanghai')
      .hours()
    const endMinute = moment(end)
      .tz('Asia/Shanghai')
      .minutes()
    return (endHour - startHour) * 150 + ((endMinute - startMinute) / 60) * 150 + 'px'
  }

  private calcBg(color): string {
    if (color) {
      const r = parseInt(color.slice(1, 3), 16)
      const g = parseInt(color.slice(3, 5), 16)
      const b = parseInt(color.slice(5, 7), 16)
      const newColor = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + '0.48)'
      return newColor
    } else {
      return '#fff'
    }
  }

  private calcSubstitute(teachers): boolean {
    return (teachers.filter(teacher => teacher.teacherId === this.id)[0] || {}).isSubstitute
      ? true
      : false
  }

  private exportCurriculum(): void {
    if (this.exportLoading) return
    this.exportLoading = true
    setTimeout(() => {
      this.exportLoading = false
    }, 3000)
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    let targetUrl = `${domain}exportCurriculum/${token}/${
      schoolInfo.schoolId
    }/${this.startTime.valueOf()}-${this.endTime.valueOf()}/${this.type}/${this.id}`
    let url = `${printUrl}api/render/?url=${targetUrl}&pdf.landscape=true&pdf.printBackground=true&pdf.scale=0.52&pdf.margin.top=4.5mm&pdf.margin.left=3.5mm&pdf.margin.right=4mm`
    setTimeout(() => {
      const fileName = this.teacherName ? `${this.teacherName}.pdf` : '课表.pdf'
      saveAs(url, fileName)
      // window.open(targetUrl)
    }, 0)
  }

  private viewDetail(course): void {
    this.materialInfo.title = this.$t('courseMaterial.subMaterialDetail')
    this.materialInfo.infoList = [
      {
        key: 'time',
        label: this.$t('adjustCurriculum.courseTime'),
        value: course.startTime
          ? moment(course.startTime)
              .tz('Asia/Shanghai')
              .format('YYYY.MM.DD')
          : '',
      },
      {
        key: 'name',
        label: this.$t('common.subjectClass'),
        value: (course.courseId || {}).description,
      },
      {
        key: 'attachment',
        label: this.$t('courseMaterial.attachment'),
        value: '',
        attachments: course.resources.map(item => {
          return {
            uid: item.resourceId,
            url: item.resourceUrl,
            name: item.resourceName,
            status: 'done',
          }
        }),
      },
    ]
    this.detailModalShow = true
  }

  private getTeachersName(course): any {
    return course ? course.teachers.map(item => item.name).join('、') : ''
  }

  private getCrossGroup(courses): any {
    const tmpCourses = cloneDeep(courses)
    let newCourses = {}
    Object.keys(tmpCourses).forEach(key => {
      let dayList: any = []
      tmpCourses[key] = tmpCourses[key].sort((a, b) => a.startTime - b.startTime)
      do {
        const course = tmpCourses[key].shift()
        let groupList = [] as any
        groupList.push({
          ...course,
          crossIndex: 0,
        })
        let index = 0
        tmpCourses[key] = tmpCourses[key]
          .map(course => {
            for (let j = 0; j < groupList.length; j++) {
              if (
                !(
                  groupList[j].startTime >= course.endTime ||
                  groupList[j].endTime <= course.startTime
                )
              ) {
                groupList.push(course)
                // let _course = cloneDeep(course)
                // _course.classArrangeId = 10002
                // groupList.push(_course)
                return undefined
              }
            }
            return course
          })
          .filter(item => item)
        dayList.push(groupList)
      } while (tmpCourses[key].length)
      dayList = dayList
        .map(group => {
          const _group = cloneDeep(group)
          group = _group
            .sort((a, b) => a.startTime - b.startTime)
            .map((item, idx) => {
              const height = 100 / Math.min(_group.length, 2) + '%'
              return {
                ...item,
                groupIndex: idx,
                crossGroupLength: _group.length,
                crossTop: 50 * (idx % 2) + '%',
                crossHeight: `calc(${height} - 1px)`,
                showAll: _group.length <= 1,
              }
            })
          return group
        })
        .flat()
      this.$set(
        newCourses,
        key,
        dayList.sort((a, b) => a.startTime - b.startTime)
      )
    })
    this.courses = newCourses
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"reject-modal",attrs:{"visible":_vm.visible,"title":'',"destroyOnClose":"","centered":"","maskClosable":false,"width":480},on:{"cancel":_vm.closeModal,"ok":_vm.confirm}},[_c('a-icon',{staticClass:"color-style",attrs:{"type":"info-circle","theme":"filled"}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"content-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('a-form',{attrs:{"form":_vm.rejectForm,"autocomplete":"nope"}},[_c('a-form-item',{attrs:{"label":_vm.$t('leaveApproval.rejectReason')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'declinedReason',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('tips.enterRejectReason'),
                },
                {
                  max: 2000,
                  message: _vm.$t('common.textLimit2000'),
                } ],
            } ]),expression:"[\n            'declinedReason',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('tips.enterRejectReason'),\n                },\n                {\n                  max: 2000,\n                  message: $t('common.textLimit2000'),\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":_vm.$t('common.enter'),"autoSize":{
            minRows: 4,
            maxRows: 10,
          }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }


































































import { Component, Vue, Model, Emit } from 'vue-property-decorator'
import {
  CurriculumController,
  DropDownController,
  CourseController,
} from '@/services/request.service'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import { createPagination } from '@/constant/constant'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class FreeClassroomFinder extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  private filter: any = {
    time: [],
    subject: [],
  }
  private subjects: Array<any> = []
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private totalData: Array<any> = []
  private data: Array<any> = []
  private timeError = false

  private get columns(): Array<any> {
    return [
      {
        key: 'classroom',
        title: this.$t('adjustCurriculum.classroom'),
        scopedSlots: { customRender: 'classroom' },
        align: 'left',
        ellipsis: true,
      },
      {
        key: 'subject',
        align: 'left',
        title: this.$t('adjustCurriculum.subject'),
        scopedSlots: { customRender: 'subject' },
        ellipsis: true,
      },
    ]
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private getDropDownInfo(): void {
    DropDownController.getSubjectList()
      .then(res => {
        this.subjects = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private findClassroom(page: any = {}): void {
    const { time, subject } = this.filter
    const { current, pageSize } = page
    if (time.length === 2) {
      this.timeError = false
      if (!current) {
        CurriculumController.getAvailableClassRooms(
          time[0].valueOf() || undefined,
          time[1].valueOf() || undefined,
          subject || undefined
        )
          .then(res => {
            this.totalData = res.data
            const size = pageSize || this.pagination.defaultPageSize
            this.data = this.totalData.slice(0, size)
            this.$set(this.pagination, 'total', res.data.length)
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        const size = pageSize || this.pagination.defaultPageSize
        this.data = this.totalData.slice((current - 1) * size, current * size)
      }
    } else {
      this.timeError = true
    }
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    const page = {
      current: pagination.current,
      pageSize: pagination.pageSize,
    }
    this.findClassroom(page)
  }

  @Emit()
  private selectClassroom(classroomId): void {
    this.closeModal()
    return classroomId
  }

  @Emit('change')
  private closeModal(): boolean {
    this.filter = {
      time: [],
      subject: [],
    }
    this.totalData = []
    this.data = []
    this.pagination = createPagination({
      showTotal: total => i18n.t('common.pageTotal', { total }),
    })
    return false
  }
}

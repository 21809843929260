





























































































import { Component, Vue } from 'vue-property-decorator'
import { TeacherController, CurriculumController } from '@/services/request.service'
import AdjustEditor from './components/AdjustEditor.vue'
import AdjustViewer from './components/AdjustViewer.vue'
import UploadModal from './components/UploadModal.vue'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { toPage } from '@/utils/utils'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    AdjustEditor,
    AdjustViewer,
    UploadModal,
    filterGroup,
  },
})
export default class MyAdjustCurriculum extends Vue {
  private statuses: Array<any> = ['waitingForAudit', 'rearranged', 'terminated', 'expired']
  private filter: any = {
    applyTime: [],
    status: 0,
  }
  private dataEmptyFlag: boolean = false
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private loading: boolean = false
  private data: Array<any> = []
  private editorVisible = false
  private viewerVisible = false
  private viewerInfo: any = {
    time: [],
    status: '',
    reason: '',
  }
  private uploadShow = false
  private modificationId = 0
  private time: Array<any> = []

  private moment = moment

  private get columns(): Array<any> {
    return [
      {
        key: 'applyTime',
        title: this.$t('adjustCurriculum.applyTime'),
        align: 'left',
        scopedSlots: { customRender: 'applyTime' },
      },
      {
        dataIndex: 'applier',
        key: 'applier',
        title: this.$t('adjustCurriculum.teacher'),
        align: 'left',
      },
      {
        key: 'status',
        title: this.$t('adjustCurriculum.status'),
        align: 'left',
        scopedSlots: { customRender: 'status' },
      },
      {
        key: 'createTime',
        title: this.$t('adjustCurriculum.createTime'),
        align: 'left',
        scopedSlots: { customRender: 'createTime' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        align: 'left',
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private get userAuth(): any {
    return this.$store.state.userAuths || {}
  }

  private created(): void {
    this.getData()
  }

  private reset(): void {
    this.filter = {
      applyTime: [],
      status: 0,
    }
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    this.data = []
    this.loading = true
    const { applyTime, status } = this.filter
    const start = applyTime[0]
      ? moment(applyTime[0])
          .startOf('day')
          .valueOf()
      : undefined
    const end = applyTime[0]
      ? moment(applyTime[1])
          .endOf('day')
          .valueOf()
      : undefined
    CurriculumController.getTeacherRearrangeLists({
      pageCurrent: page.current,
      pageSize: page.pageSize,
      start,
      end,
      status: status ? status : undefined,
    })
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private add(): void {
    this.editorVisible = true
    this.modificationId = 0
    this.time = []
  }

  private edit(record): void {
    this.editorVisible = true
    this.modificationId = record.modificationId
    this.time = [moment(record.start), moment(record.end)]
  }

  private view(record): void {
    this.viewerVisible = true
    this.modificationId = record.modificationId
    this.viewerInfo = {
      time: [moment(record.start), moment(record.end)],
      status: record.status,
      reason: record.reason,
    }
  }

  private terminate(modificationId): void {
    CurriculumController.stopArrange(modificationId, '')
      .then(res => {
        this.$message.success(this.$tc('common.terminateSuccess'))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => this.refresh())
  }

  private uploadResource(item): void {
    this.uploadShow = true
    this.modificationId = item.modificationId
  }

  private refresh(): void {
    this.getData(this.pagination)
  }
}

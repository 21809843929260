






























































import { Component, Vue, Model, Emit, Prop, Watch } from 'vue-property-decorator'
import {
  CurriculumController,
  DropDownController,
  CourseController,
} from '@/services/request.service'
import moment from 'moment'

@Component
export default class AdjustEditor extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly modificationId!: number
  @Prop() private readonly time!: Array<any>
  private saveLoading = false
  private document = document

  private filter: any = {
    time: [],
  }
  private data: Array<any> = []

  private moment = moment

  private get columns(): Array<any> {
    return [
      {
        key: 'courseTime',
        title: this.$t('adjustCurriculum.courseTime'),
        align: 'left',
        scopedSlots: { customRender: 'courseTime' },
      },
      {
        dataIndex: 'name',
        key: 'courseClass',
        title: this.$t('adjustCurriculum.courseClass'),
        align: 'left',
      },
      {
        dataIndex: 'subject',
        key: 'subject',
        title: this.$t('adjustCurriculum.subject'),
        align: 'left',
      },
      {
        dataIndex: 'grade',
        key: 'section',
        title: this.$t('adjustCurriculum.section'),
        align: 'left',
      },
      {
        dataIndex: 'classRoom',
        key: 'classroom',
        title: this.$t('adjustCurriculum.classroom'),
        align: 'left',
      },
    ]
  }

  @Watch('visible')
  private onVisibleChange(newVal): void {
    if (newVal) {
      if (this.modificationId) {
        this.$set(this.filter, 'time', this.time)
        this.getCourseList(this.filter.time)
      }
    }
  }

  private range(start, end): any {
    let result = [] as any
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  private disabledDate(current): boolean {
    return current && current < moment().startOf('day')
  }

  private disabledTime(dates, type): any {
    if (type === 'start') {
      if (dates && dates[0]) {
        const startTime = moment(dates[0])
        if (
          startTime.startOf('day').valueOf() ===
          moment()
            .startOf('day')
            .valueOf()
        ) {
          const hour = moment().hours()
          const minute = moment().minutes()
          const second = moment().seconds()
          if (dates[0].hours() === hour) {
            if (dates[0].minutes() === minute) {
              return {
                disabledHours: () => this.range(0, hour),
                disabledMinutes: () => this.range(0, minute),
                disabledSeconds: () => this.range(0, second),
              }
            } else {
              return {
                disabledHours: () => this.range(0, hour),
                disabledMinutes: () => this.range(0, minute),
                disabledSeconds: () => [],
              }
            }
          } else {
            return {
              disabledHours: () => this.range(0, hour),
              disabledMinutes: () => [],
              disabledSeconds: () => [],
            }
          }
        }
      }
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    }
  }

  private getCourseList(dates): void {
    if (dates.length) {
      const start = dates[0].valueOf()
      const end = dates[1].valueOf()
      CurriculumController.getRearrange(start, end)
        .then(res => {
          this.data = res.data.rearrangeCourses
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  @Emit('change')
  private closeModal(): boolean {
    this.filter = {
      time: [],
    }
    this.data = []
    return false
  }

  private confirmAdjust(): void {
    this.saveLoading = true
    if (this.data.length) {
      const data = {
        modificationId: this.modificationId || undefined,
        startTime: this.filter.time[0].valueOf(),
        endTime: this.filter.time[1].valueOf(),
      }
      CurriculumController.rearrange(data)
        .then(res => {
          if (res.data.status === 200) {
            this.$message.success(this.$tc('common.submitSuccess'))
            this.closeModal()
          } else if (res.data.status === 516) {
            const period = res.data.data.crossingPeriods
              .map(item => {
                return (
                  moment(item.start).format('YYYY.MM.DD HH:mm') +
                  ' ~ ' +
                  moment(item.end).format('YYYY.MM.DD HH:mm')
                )
              })
              .join(',')
            this.$error({
              title: this.$t('adjustCurriculum.duplicateTime', { period }),
            })
          } else if (res.data.status === 517) {
            this.$message.error(this.$tc('tips.noCourseArrange'))
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.saveLoading = false
        })
    } else {
      this.$message.error(this.$tc('adjustCurriculum.noCourse'))
      this.saveLoading = false
    }
  }
}

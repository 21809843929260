















































import { Component, Vue } from 'vue-property-decorator'
import { TeacherController, CurriculumController } from '@/services/request.service'
import { mapState } from 'vuex'
import moment, { Moment } from 'moment'
import FreeTeacherFinder from './components/FreeTeacherFinder.vue'
import allCoursesThisYear from './components/allCoursesThisYear.vue'
import flattenDeep from 'lodash/flattenDeep'
import curriculum from '@/components/curriculum.vue'
import courseCard from '@/components/courseCard.vue'
import filterGroup from '@/components/filterGroup'

@Component({
  components: {
    FreeTeacherFinder,
    curriculum,
    filterGroup,
    courseCard,
    allCoursesThisYear,
  },
  computed: {
    ...mapState({
      operationAuths: 'operationAuths',
    }),
  },
})
export default class TeacherCurriculum extends Vue {
  private filter: any = {
    teacherId: undefined,
  }
  private teachers: Array<any> = []
  private startTime = moment().startOf('isoWeek')
  private endTime = moment().endOf('isoWeek')
  private curriculumData: any = {
    substitute: 0,
    total: 0,
    courses: {},
  }
  private findVisible = false
  private allCourses = false
  private loading: boolean = false
  private moment = moment

  private get teacherName(): any {
    const teacher = this.teachers.find(item => item.teacherId === this.filter.teacherId) || {
      enName: '',
      name: '',
    }
    return (teacher.enName + ' ' + teacher.name).trim()
  }

  private get week(): Array<any> {
    return [
      {
        name: '周一',
        enName: 'Mon',
        dayOfWeek: 1,
      },
      {
        name: '周二',
        enName: 'Tue',
        dayOfWeek: 2,
      },
      {
        name: '周三',
        enName: 'Wed',
        dayOfWeek: 3,
      },
      {
        name: '周四',
        enName: 'Thu',
        dayOfWeek: 4,
      },
      {
        name: '周五',
        enName: 'Fri',
        dayOfWeek: 5,
      },
    ]
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private getDropDownInfo(): void {
    TeacherController.getListAll()
      .then(res => {
        this.teachers = res.data
        if (this.teachers.length) {
          this.filter.teacherId = this.teachers[0].teacherId
          this.getTeacherCurriculum(this.filter.teacherId)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getTeacherCurriculum(value): void {
    const start = this.startTime.valueOf()
    const end = this.endTime.valueOf()
    this.loading = true
    this.curriculumData = {
      substitute: 0,
      total: 0,
      courses: {},
    }
    CurriculumController.getClassArrangesByTeacherAndWeek(value, start, end)
      .then(res => {
        this.curriculumData = {
          substitute: res.data.institute,
          total: res.data.regular + res.data.institute,
          courses: res.data.classArranges,
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private selectTeacher(teacherId): void {
    this.$set(this.filter, 'teacherId', teacherId)
    this.getTeacherCurriculum(teacherId)
  }

  private onTimeChange(params): void {
    if (!params && !params.length) return
    this.startTime = params[0]
    this.endTime = params[1]
    this.getTeacherCurriculum(this.filter.teacherId)
  }
}























import { Component, Vue } from 'vue-property-decorator'
import { CurriculumController } from '@/services/request.service'
import moment, { Moment } from 'moment'
import curriculum from '@/components/curriculum.vue'
import flattenDeep from 'lodash/flattenDeep'
import courseCard from '@/components/courseCard.vue'
import allCoursesThisYear from '@/views/AdjustCurriculum/components/allCoursesThisYear.vue'
@Component({
  components: {
    curriculum,
    courseCard,
    allCoursesThisYear,
  },
})
export default class MyCurriculum extends Vue {
  private startTime = moment().startOf('isoWeek')
  private endTime = moment().endOf('isoWeek')
  private curriculumData: any = {
    courses: {},
    total: 0,
    substitute: 0,
  }
  private loading: boolean = false
  private allCourses = false
  private get userName(): any {
    return this.$store.state.username
  }

  private created(): void {
    this.getMyCurriculum()
  }

  private getMyCurriculum(): void {
    const start = this.startTime.valueOf()
    const end = this.endTime.valueOf()
    this.loading = true
    CurriculumController.getPersonalCurriculum(start, end)
      .then(res => {
        this.curriculumData = {
          courses: res.data.classArranges,
          total: res.data.regular,
          substitute: res.data.institute,
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private onTimeChange(params): void {
    if (!params && !params.length) return
    this.startTime = params[0]
    this.endTime = params[1]
    this.getMyCurriculum()
  }
}

















































import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator'
import { AttendanceController } from '@/services/request.service'

@Component
export default class RejectModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly title!: any

  private loading: boolean = false
  private rejectForm: any

  private created(): void {
    this.rejectForm = this.$form.createForm(this)
  }

  private confirm(): void {
    this.rejectForm.validateFields((err: any, values: any) => {
      if (err) {
        this.loading = false
        return
      } else {
        this.$emit('confirm', { reason: values.declinedReason })
      }
    })
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }
}





























import { Component, Vue, Model, Emit } from 'vue-property-decorator'
import {
  CurriculumController,
  DropDownController,
  CourseController,
} from '@/services/request.service'
import PeriodEditor from './PeriodEditor.vue'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
@Component({
  components: {
    PeriodEditor,
  },
})
export default class FreeTeacherFinder extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  private list: Array<any> = []
  private activeKey: any = ''
  private created(): void {
    console.log('created')
    setTimeout(() => {
      console.log('this.activeKey:', this.activeKey)
    }, 1000)
    this.init()
  }
  private init(): void {
    CurriculumController.getClassPeriodSettingList().then(res => {
      console.log('res:', res)
      if (res.status == 200) {
        this.list = res.data
        if (this.list.length > 0) {
          this.activeKey = this.list[0].classPeriodSettingId
        }
      }
    })
  }
  private changeTable(val): void {
    console.log('changeTable', val)
  }
  @Emit('change')
  private closeModal(): boolean {
    return false
  }
}

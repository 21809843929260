























import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { CurriculumController } from '@/services/request.service'
import moment from 'moment'

@Component
export default class PeriodEditor extends Vue {
  @Prop() private readonly type!: string
  @Prop() private readonly periodId!: any
  private periodEditor: any
  private data: any = {
    targetSections: [],
  }
  @Watch('periodId', { immediate: true, deep: true })
  onPeriodIdChange(val) {
    val && this.getData(val)
  }
  private getData(periodId): void {
    const classPeriodSettingId = parseInt(periodId, 10)
    CurriculumController.getClassPeriod(classPeriodSettingId)
      .then(res => {
        this.data = res.data
        let table = res.data.classPeriods.map(item => {
          return {
            classPeriodId: item.classPeriodId,
            description: item.description,
            time: moment(item.start).format('HH:mm') + '-' + moment(item.end).format('HH:mm'),
            arranges: [...Array(5).fill({ col: 1, id: 0, content: '' })],
          }
        })
        res.data.periodArranges.forEach(arr => {
          table.forEach(cell => {
            if (arr.classPeriodId === cell.classPeriodId) {
              ;(arr.dayOfWeeks || []).slice(0, 5).forEach((day, index) => {
                if (day) {
                  cell.arranges[index] = {
                    col: 1,
                    id: arr.periodArrangeId,
                    content: arr.content,
                  }
                }
              })
            }
          })
        })
        table.forEach(cell => {
          cell.arranges.forEach((arr, index) => {
            if (index !== 0) {
              for (let i = index - 1; i >= 0; i--) {
                let item = cell.arranges[i]
                if (arr.id === item.id && arr.id !== 0) {
                  if (item.col) {
                    item.col = item.col + 1
                    arr.col = 0
                    break
                  }
                } else {
                  break
                }
              }
            }
          })
          cell.arranges = cell.arranges.filter(arr => arr.col)
        })
        this.data = {
          ...res.data,
          table,
        }
      })
      .catch(err => {
        console.error(err)
      })
  }
}

















































import { Component, Vue } from 'vue-property-decorator'
import { CurriculumController, DropDownController } from '@/services/request.service'
import moment, { Moment } from 'moment'
import FreeClassroomFinder from './components/FreeClassroomFinder.vue'
import flattenDeep from 'lodash/flattenDeep'
import curriculum from '@/components/curriculum.vue'
import { clearEmptyArray, createMergeArray } from '@/utils/utils'
import filterGroup from '@/components/filterGroup'
import courseCard from '@/components/courseCard.vue'
@Component({
  components: {
    FreeClassroomFinder,
    curriculum,
    filterGroup,
    courseCard,
  },
})
export default class ClassroomCurriculum extends Vue {
  private filter: any = {
    classroomId: undefined,
  }
  private classrooms: Array<any> = []
  private startTime = moment().startOf('isoWeek')
  private endTime = moment().endOf('isoWeek')
  private curriculumData = {
    total: 0,
    courses: {},
  }
  private findVisible = false
  private loading: boolean = false

  private moment = moment

  private get classroomName(): any {
    const classroom = this.classrooms.find(item => item.key === this.filter.classroomId) || {
      value: '',
      enValue: '',
    }
    return this.locale === 'zh' ? classroom.value : classroom.enValue || classroom.value
  }

  private get week(): Array<any> {
    return [
      {
        name: '周一',
        enName: 'Mon',
        dayOfWeek: 1,
      },
      {
        name: '周二',
        enName: 'Tue',
        dayOfWeek: 2,
      },
      {
        name: '周三',
        enName: 'Wed',
        dayOfWeek: 3,
      },
      {
        name: '周四',
        enName: 'Thu',
        dayOfWeek: 4,
      },
      {
        name: '周五',
        enName: 'Fri',
        dayOfWeek: 5,
      },
    ]
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private getDropDownInfo(): void {
    DropDownController.getClassRoomCascade()
      .then(res => {
        let classrooms = []
        res.data.forEach(item => {
          createMergeArray(item, classrooms)
        })
        this.classrooms = classrooms
        if (this.classrooms.length) {
          this.filter.classroomId = this.classrooms[0].key
          this.getClassroomCurriculum(this.filter.classroomId)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getClassroomCurriculum(value): void {
    this.loading = true
    const start = this.startTime.valueOf()
    const end = this.endTime.valueOf()
    this.curriculumData.courses = {}
    this.curriculumData.total = 0
    CurriculumController.getClassArrangesByClassRoomAndWeek(value, start, end)
      .then(res => {
        this.curriculumData.courses = res.data
        this.curriculumData.total = flattenDeep(Object.values(res.data)).length
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private selectClassroom(classroomId): void {
    this.$set(this.filter, 'classroomId', classroomId)
    this.getClassroomCurriculum(classroomId)
  }

  private onTimeChange(params): void {
    if (!params && !params.length) return
    this.startTime = params[0]
    this.endTime = params[1]
    this.getClassroomCurriculum(this.filter.classroomId)
  }
}

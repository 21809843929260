var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"courseCard"},[_c('div',{staticClass:"info-container"},[_c('div',{staticClass:"curriculum-time"},[_c('a-button',{staticClass:"btns",staticStyle:{"color":"#000"},attrs:{"type":"link","icon":"left"},on:{"click":_vm.prevWeek}}),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.$d(_vm.startTime, 'short') + '-' + _vm.$d(_vm.endTime, 'short')))]),_c('a-button',{staticClass:"btns",staticStyle:{"color":"#000"},attrs:{"type":"link","icon":"right"},on:{"click":_vm.nextWeek}})],1),_c('div',{staticClass:"curriculum-other"},[_c('i18n',{staticClass:"total",attrs:{"path":_vm.type.indexOf('teacher') === -1
            ? 'adjustCurriculum.classroomTotal'
            : 'adjustCurriculum.teacherTotal',"tag":"span"},scopedSlots:_vm._u([{key:"total",fn:function(){return [_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.total))])]},proxy:true},{key:"substitute",fn:function(){return [_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.substitute))])]},proxy:true}])}),_c('a-button',{attrs:{"loading":_vm.exportLoading},on:{"click":_vm.exportCurriculum}},[_c('a-icon',{attrs:{"type":"vertical-align-bottom"}}),_c('span',[_vm._v(_vm._s(_vm.$t('common.export')))])],1)],1)]),_c('div',{staticClass:"curriculum-table"},[_c('div',{staticClass:"curriculum-week-block"},_vm._l((_vm.week),function(day){return _c('div',{key:day.dayOfWeek,staticClass:"curriculum-row"},[_c('span',[_vm._v(_vm._s(day.toDay))]),_c('i',[_vm._v(_vm._s(day.enName)+" "+_vm._s(day.name))])])}),0),_c('div',{staticClass:"table-container"},[_c('div',{staticClass:"time-box"},[_c('div',{staticClass:"period-item-first"}),_vm._l((_vm.periods),function(period){return _c('div',{key:period.classPeriodId,staticClass:"period-item"},[_c('span',[_vm._v(_vm._s(_vm.moment(period.start) .tz('Asia/Shanghai') .format('HH:mm')))])])}),_c('div',{staticClass:"fill-block"})],2),_c('div',{staticClass:"main"},_vm._l((_vm.week),function(day){return _c('div',{key:day.dayOfWeek,staticClass:"week-box"},[_c('div',{staticClass:"fill-head-box"}),_vm._l((_vm.periods),function(period){return _c('div',{key:period.classPeriodId,staticClass:"period-row"})}),_c('div',{staticClass:"fill-box"}),_vm._l((_vm.courses[day.dayOfWeek]),function(course){return _c('div',{key:course.classArrangeId,staticClass:"course-item-outer",style:({
              height: _vm.calcHeight(course.startTime, course.endTime),
              top: _vm.calcTop(course.startTime),
              width: course.crossWidth,
              left: course.crossLeft,
              backgroundColor:
                course.type === '1001'
                  ? _vm.calcBg(course.courseId.subject.color)
                  : 'rgba(70,194,154,0.2)',
            })},[_c('a-tooltip',{attrs:{"overlayClassName":"toolTipBox"}},[_c('template',{slot:"title"},[_c('p',[_vm._v(" "+_vm._s(_vm.moment(course.startTime) .tz('Asia/Shanghai') .format('HH:mm') + '-' + _vm.moment(course.endTime) .tz('Asia/Shanghai') .format('HH:mm'))+" ")]),(_vm.type !== 'classroom' || _vm.type === 'student')?_c('p',[_vm._v(" "+_vm._s(_vm.getTeachersName(course))+" ")]):_vm._e(),(_vm.type !== 'classroom' || _vm.type === 'student')?_c('p',[_vm._v(" "+_vm._s((course.classRoomId || {}).code)+" ")]):_vm._e(),(course.courseId.subject)?_c('p',[_vm._v(_vm._s(course.courseId.subject.description))]):_vm._e(),(course.courseId.description)?_c('p',[_vm._v(_vm._s(course.courseId.description))]):_vm._e()]),_c('div',{staticClass:"course-item"},[(_vm.calcSubstitute(course.teachers) && _vm.type !== 'classroom')?_c('div',{staticClass:"substitute-badge"},[_vm._v(" 代 "),_c('div',{staticClass:"bottom"})]):_vm._e(),_c('div',{staticClass:"side-bar",style:({
                    backgroundColor:
                      course.type === '1001'
                        ? course.courseId.subject.color === '#FFFFFF'
                          ? '#ccc'
                          : course.courseId.subject.color
                        : 'transparent',
                  })}),_c('div',{staticClass:"course-content",class:{ 'primary-color-font': course.type !== '1001' }},[_c('div',[_vm._v(" "+_vm._s(_vm.moment(course.startTime) .tz('Asia/Shanghai') .format('HH:mm') + '-' + _vm.moment(course.endTime) .tz('Asia/Shanghai') .format('HH:mm'))+" ")]),(_vm.type !== 'classroom' || _vm.type === 'student')?_c('span',[_vm._v(_vm._s((course.classRoomId || {}).code))]):_vm._e(),(_vm.type === 'classroom' || _vm.type === 'student')?_c('span',[_vm._v(_vm._s(_vm.getTeachersName(course)))]):_vm._e(),(course.courseId.description && course.showThird)?_c('span',[_vm._v(_vm._s(course.courseId.description))]):_vm._e()]),_c('a-tooltip',{attrs:{"title":_vm.$t('common.downloadResource'),"getPopupContainer":function () { return _vm.$document.body; }}},[(_vm.type !== 'classroom' && (course.resources || []).length)?_c('div',{staticClass:"download-material-btn",on:{"click":function($event){return _vm.viewDetail(course)}}},[_c('a-icon',{attrs:{"type":"file-zip","theme":"filled"}})],1):_vm._e()])],1)],2)],1)})],2)}),0)]),_c('detail-modal',{attrs:{"detailInfo":_vm.materialInfo},scopedSlots:_vm._u([{key:"attachment",fn:function(slotScope){return [_c('span',{staticClass:"label-content"},[_vm._v(_vm._s(((slotScope.item.label) + "： ")))]),_c('div',{staticClass:"attachment-container"},_vm._l((slotScope.item.attachments),function(attachment,index){return _c('div',{key:index,staticClass:"single-attachment",on:{"click":function($event){return _vm.$aDownload(attachment.url, attachment.name)}}},[_c('a-icon',{attrs:{"type":"paper-clip"}}),_c('span',[_vm._v(_vm._s(attachment.name))]),_c('a-icon',{attrs:{"type":"download"},on:{"click":function($event){$event.stopPropagation();return _vm.$aDownload(attachment.url, attachment.name, true)}}})],1)}),0)]}}]),model:{value:(_vm.detailModalShow),callback:function ($$v) {_vm.detailModalShow=$$v},expression:"detailModalShow"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }




































































import { Component, Vue, Model, Prop, Emit, Watch } from 'vue-property-decorator'
import {
  CurriculumController,
  DropDownController,
  CourseController,
} from '@/services/request.service'
import moment from 'moment'

@Component
export default class AdjustViewer extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly modificationId!: number
  @Prop() private readonly info!: any

  private data: Array<any> = []

  private moment = moment

  private get columns(): Array<any> {
    return [
      {
        key: 'courseTime',
        title: this.$t('adjustCurriculum.courseTime'),
        scopedSlots: { customRender: 'courseTime' },
        align: 'left',
      },
      {
        key: 'teacher',
        title: this.$t('adjustCurriculum.teacher'),
        scopedSlots: { customRender: 'teacher' },
        align: 'left',
      },
      {
        dataIndex: 'name',
        key: 'courseClass',
        title: this.$t('adjustCurriculum.courseClass'),
        align: 'left',
      },
      {
        dataIndex: 'subject',
        key: 'subject',
        title: this.$t('adjustCurriculum.subject'),
        align: 'left',
      },
      {
        dataIndex: 'grade',
        key: 'section',
        title: this.$t('adjustCurriculum.section'),
        align: 'left',
      },
      {
        dataIndex: 'classRoom',
        key: 'classroom',
        title: this.$t('adjustCurriculum.classroom'),
        align: 'left',
      },
    ]
  }

  @Watch('visible')
  private onVisibleChange(newVal): void {
    if (newVal) {
      if (this.modificationId) {
        this.getCourseList()
      }
    }
  }

  private getCourseList(): void {
    CurriculumController.getRearrangeById(this.modificationId)
      .then(res => {
        this.data = res.data.rearrangeCourses
      })
      .catch(err => {
        console.error(err)
      })
  }

  @Emit('change')
  private closeModal(): boolean {
    this.data = []
    return false
  }
}



































































import { Component, Vue, Model, Prop, Emit, Watch } from 'vue-property-decorator'
import {
  CurriculumController,
  DropDownController,
  CourseController,
  ClassMaterialController,
} from '@/services/request.service'
import moment from 'moment'
import { fileUploader } from '@/services/qiniu.service'
import { guid } from '@/utils/utils'

@Component
export default class AdjustViewer extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly modificationId!: number

  private data: Array<any> = []
  private totalLoadingCount = 0
  private fileList: any = []
  private tableLoading = false
  private moment = moment
  private currentFileName = ''

  private get columns(): Array<any> {
    return [
      {
        key: 'courseTime',
        title: this.$t('adjustCurriculum.courseTime'),
        scopedSlots: { customRender: 'courseTime' },
      },
      {
        key: 'teacher',
        title: this.$t('adjustCurriculum.teacher'),
        scopedSlots: { customRender: 'teacher' },
      },
      {
        dataIndex: 'name',
        key: 'courseClass',
        title: this.$t('adjustCurriculum.courseClass'),
      },
      {
        dataIndex: 'subject',
        key: 'subject',
        title: this.$t('adjustCurriculum.subject'),
      },
      {
        dataIndex: 'grade',
        key: 'section',
        title: this.$t('adjustCurriculum.section'),
      },
    ]
  }

  private get progress(): any {
    return this.$store.state.fileUploadProgress
  }

  @Watch('progress', { deep: true, immediate: true })
  private onProgressChange(val): any {
    Object.keys(val).forEach(key => {
      if (val[key] <= 100) {
        let attachment = this.fileList.find(attachment => attachment.url.includes(key))
        if (attachment && attachment.status === 'uploading') {
          attachment.percent = val[key]
        }
      }
    })
  }

  @Watch('visible')
  private onVisibleChange(newVal): void {
    if (newVal) {
      if (this.modificationId) {
        this.getCourseList()
      }
    }
  }

  private getCourseList(): void {
    this.tableLoading = true
    CurriculumController.getRearrangeById(this.modificationId)
      .then(res => {
        this.data = res.data.rearrangeCourses.map(item => {
          let attachments = item.resources.map(resource => {
            return {
              uid: resource.resourceId,
              url: resource.resourceUrl,
              name: resource.resourceName,
              status: 'done',
            }
          })
          this.$set(item, 'attachments', attachments)
          this.$set(item, 'uploadingCount', 0)
          return item
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.tableLoading = false))
  }

  @Emit('change')
  private closeModal(): boolean {
    this.data = []
    return false
  }

  private customRequest(file, record): void {
    if (record.attachments.length >= 5) return
    const index = file.name.lastIndexOf('.')
    const suffix = file.name.slice(index).toLowerCase()
    const fileName = 'file_' + new Date().getTime() + suffix
    const suffixArray = ['.exe', '.bat', '.com'] as any
    if (suffixArray.includes(suffix)) {
      const tips = suffixArray.join(',').replace(/\./g, '')
      this.$message.error(
        this.$t('tips.rejectFileTips', { tips: suffixArray.join(', ') }) as string
      )
      return
    }
    if (file.size > 100 * 1024 * 1024) {
      this.$message.error(this.$t('tips.attachmentTip', { size: '100M' }) as string)
      return
    }
    let attachment = {
      uid: guid() as any,
      url: process.env.VUE_APP_FILE_URL + fileName,
      name: file.name,
      status: 'uploading',
      percent: 0,
    }
    this.fileList.push(attachment)
    record.attachments.push(attachment)

    record.uploadingCount++
    this.totalLoadingCount++
    fileUploader(file, fileName, 'file')
      .then(res => {
        attachment.uid = res.resourceId
        attachment.status = 'done'
        let condition = {
          classArrangeId: record.arrangeId,
          resourceId: res.resourceId,
        }
        ClassMaterialController.saveSubstituteResource(condition)
          .then(res => {
            this.$message.success(this.$tc('common.uploadSuccess'))
          })
          .finally(() => {
            record.uploadingCount--
            this.totalLoadingCount--
          })
      })
      .catch(err => {
        attachment.status = 'error'
        this.$set(attachment, 'error', { statusText: this.$t('tips.uploadFailed') })
        record.uploadingCount--
        this.totalLoadingCount--
        console.error(err)
      })
  }

  private removeFile(file, record): void {
    if (record.uploadingCount) return
    let index = record.attachments.indexOf(file)
    let tempList = record.attachments
    let condition = {
      classArrangeId: record.arrangeId,
      resourceId: file.uid,
    }
    record.uploadingCount++
    this.totalLoadingCount++
    ClassMaterialController.deleteSubstituteResource(condition)
      .then(res => {
        this.$message.success(this.$tc('common.deleteSuccess'))
        tempList.splice(index, 1)
        record.attachments = tempList
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        record.uploadingCount--
        this.totalLoadingCount--
      })
  }
}
